import React, { Dispatch, SetStateAction } from "react";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import { getData, newData } from "./functions";

import { TestimonialsData, ApiResult } from "./Testimonials";

interface IProps {
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    setData: Dispatch<SetStateAction<ApiResult | undefined>>;
}

const TestimonialsImportExport = ({ setIsLoading, setData }: IProps): JSX.Element => {
    return (<Box sx={{ display: "flex", justifyContent: "flex-start", marginTop: "1rem" }}>
        <Button sx={{ marginRight: "1rem" }} variant="contained" size="large" color="info" onClick={async () => {
            const res = await getData(0, 99999);
            if (res) {
                const json = res.testimonials.map((testimonial: TestimonialsData) => {
                    return {
                        date: testimonial.date,
                        name: testimonial.name,
                        stars: testimonial.stars,
                        comment: testimonial.comment
                    };
                });
                const element = document.createElement("a");
                const file = new Blob([JSON.stringify(json)], { type: "text/plain" });
                element.href = URL.createObjectURL(file);
                element.download = "testimonials.json";
                document.body.appendChild(element);
                element.click();
            }
        }}>Exporter</Button>
        <Button sx={{ marginLeft: "1rem" }} variant="contained" size="large" color="info" component="label">
            Importer
            <input type="file" hidden onChange={async (e) => {
                if (e.target.files && e.target.files.length > 0) {
                    const file = e.target.files[0];
                    const text = await file.text();
                    const json = JSON.parse(text);
                    const testimonials = json.map((testimonial: any) => {
                        return {
                            date: new Date(testimonial.date),
                            name: testimonial.name,
                            stars: testimonial.stars,
                            comment: testimonial.comment
                        };
                    });
                    for (const testimonial of testimonials) {
                        await newData(testimonial);
                    }
                    getData().then((res) => {
                        if (res) setData(res);
                        setIsLoading(false);
                    });
                }
            }} />
        </Button>
    </Box>);
}

export default TestimonialsImportExport;