import React from "react";

import Button from "../../Button";

import {scrollToSection} from "../Navbar/Navbar";

import "./Home.scss";

interface IProps {
    text: any;
    dataContact: any;
}

const Home = ({text, dataContact}: IProps): React.JSX.Element => {

    const address = dataContact.find((data: any) => data.name === "address").data;

    return (
        <section id="home" className="home">
            <div className="home__background"></div>
            <div className="home__content">
                <h1 className="home__content__text home__content__title">Clément de Villepin</h1>
                <p className="home__content__text home__content__subtitle">{text.global.chiropractorAt} {address.city}</p>
                <p className="home__content__text home__content__target">{text.home.public}</p>
                <a onClick={() => scrollToSection("contact")}><Button text={text.home.button} className="home__content__text home__button"/></a>
            </div>
        </section>
    );
}

export default Home;