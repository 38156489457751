import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from '@mui/material/Button';

import Pagination from '@mui/material/Pagination';

import { FaStar } from "react-icons/fa";

import { getData, deleteData } from "./functions";

import TestimonialsModal from "./TestimonialsModal";
import TestimonialsImportExport from "./TestimonialsImportExport";

export interface TestimonialsData {
    id: string;
    date: Date;
    name: string;
    stars: number;
    comment: string
}

export interface ApiResult {
    testimonials: TestimonialsData[];
    total: number;
}

const Testimonials = (): React.JSX.Element => {

    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [data, setData] = useState<ApiResult>();

    const defaultModalData: TestimonialsData = { id: "", date: new Date(), name: "", stars: 0, comment: "" };
    const [modalData, setModalData] = useState<TestimonialsData>(defaultModalData);
    const [open, setOpen] = React.useState(false);
    const openModal = (testimonial?: TestimonialsData) => {
        setOpen(true);
        if (testimonial)
            setModalData(testimonial);
    };
    const closeModal = (reset?: boolean) => {
        setOpen(false);
        if (reset)
            setModalData(defaultModalData);
    };

    useEffect(() => {
        if (data === undefined) {
            getData().then((res) => {
                if (res) setData(res);
                setIsLoading(false);
            });
        }
    }, [data]);

    return (<Box sx={{ width: "100%" }}>
        {isLoading ? <div>Loading...</div> : <>
            <Box sx={{ display: "flex", justifyContent: "space-between", verticalAlign: "middle", alignItems: "center" }}>
                <Typography variant="h4" component="h1" gutterBottom>Témoignages</Typography>
                <Button variant="contained" onClick={() => openModal()} size="large" color="info">Ajouter un témoignage</Button>
            </Box>
            {data && <Box style={{ marginTop: "1rem" }}>
                <Typography variant="h5" component="h2" gutterBottom>{data.total} témoignage{data.total as number > 1 ? "s" : ""}</Typography>
                {data.total > 0 && <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "2rem" }}>
                    <Pagination count={Math.ceil(data.total / 6)} shape="rounded" page={page + 1}
                        onChange={(event, page) => {
                            getData(page - 1).then((res): void => {
                                if (res) setData(res);
                                setIsLoading(false);
                            });
                            setPage(page - 1);
                        }}
                    />
                </Box>}
                <Box style={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "flex-start", margin: "2rem" }}>
                    {data.testimonials.map((testimonial: TestimonialsData) => (
                        <Card sx={{ width: "calc(100% / 3 - 4rem)", margin: "1rem 2rem" }} key={testimonial.id}>
                            <CardContent>
                                <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <Typography gutterBottom variant="h5" component="div">{testimonial.name}</Typography>
                                    <Typography gutterBottom variant="h5" component="div">{testimonial.stars.toString().replace('.', ',')} <FaStar style={{ color: "#f1c40f", display: "inline-block", verticalAlign: "text-top", }} /></Typography>
                                </Box>
                                <Typography variant="body2" color="text.secondary">{testimonial.comment}</Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" onClick={() => {
                                    openModal(testimonial);
                                }}>Éditer</Button>
                                <Button size="small" onClick={() => {
                                    deleteData(testimonial.id).then((res) => {
                                        if (res) {
                                            getData(page).then((res): void => {
                                                if (res) setData(res);
                                                setIsLoading(false);
                                            });
                                        }
                                    });
                                }}>Supprimer</Button>
                            </CardActions>
                        </Card>
                    ))}
                </Box>
            </Box>}
            {open && <TestimonialsModal open={open} handleClose={closeModal} data={modalData} newItem={modalData.id === ""} setModalData={setModalData} setData={setData} />}
        </>}
        <TestimonialsImportExport setIsLoading={setIsLoading} setData={setData} />
    </Box>);
}

export default Testimonials;