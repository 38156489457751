import React, { useState } from "react";
import sha256 from 'crypto-js/sha256';

import "./Login.scss"
import { ApiUrl } from "../../../../App";

interface IProps {
    setToken: (token: string) => void;
    setRefreshToken: (refreshToken: string) => void;
}

const hashPassword = (password: string): string => {
    return (sha256(password).toString());
};

const Login = ({setToken, setRefreshToken}: IProps): React.JSX.Element => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const res: Response = await fetch(ApiUrl + 'api/user/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({username, password: hashPassword(password)})
        });
        const data: any = await res.json();
        if (data.token) {
            setToken(data.token);
            setRefreshToken(data.refreshToken);
            localStorage.setItem('token', data.token);
            localStorage.setItem('refreshToken', data.refreshToken);
            setUsername('');
            setPassword('');
        }
    };

    return (
        <div className="login-form">
            <h1>Formulaire de Connexion</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="username">Nom d'utilisateur</label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="password">Mot de Passe</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">Se Connecter</button>
            </form>
        </div>
    );
}

export default Login;