import React from "react";

import { Box, TextField, Typography } from "@mui/material";

interface IProps {
    dataList: any;
    data: any;
    size: (size: number) => Object;
    setData: (data: Object) => void;
}

const Navbar = ({ dataList, data, size, setData }: IProps): React.JSX.Element => {
    return (<Box sx={{ width: '100%' }}>
        <Typography variant="h5" gutterBottom>Navbar</Typography>
        {Object.keys(dataList.data.navbar).map((key: string) => (
            <TextField
                key={key} required label={(dataList.data.navbar as any)[key]} value={data.data.navbar[key]}
                onChange={(e) => {
                    setData({ ...data, data: { ...data.data, navbar: { ...data.data.navbar, [key]: e.target.value } } });
                }}
                sx={size(25)}
            />
        ))}
    </Box>)
};

export default Navbar;