import React from "react";

import "./Home.scss";

const Home = (): React.JSX.Element => {

    return (<section className="AdminHome">
        <h1 className="AdminHome__title">Bienvenue dans l'espace d'administration</h1>
    </section>)
}

export default Home;