import React from "react";

import { Box, TextField, Typography, IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface IProps {
    dataList: any;
    data: any;
    size: (size: number) => Object;
    setData: (data: Object) => void;
}

const numbers: string[] = ["¹", "²", "³", "⁴", "⁵", "⁶", "⁷", "⁸", "⁹", "⁰"];

const Chiropractics = ({ dataList, data, size, setData }: IProps): React.JSX.Element => {
    return (<Box sx={{ width: '100%' }}>
        <Typography variant="h5" gutterBottom>La chiropraxie</Typography>
        <TextField
            required label={dataList.data.chiropractics.title} value={data.data.chiropractics.title}
            onChange={(e) => { setData({ ...data, data: { ...data.data, chiropractics: { ...data.data.chiropractics, title: e.target.value } } }) }}
            sx={size(100)}
        />
        {Object.keys(dataList.data.chiropractics.menu).map((key: string) => (
            <TextField
                key={key} required label={(dataList.data.chiropractics.menu)[key]} value={data.data.chiropractics.menu[key]}
                onChange={(e) => {
                    setData({ ...data, data: { ...data.data, chiropractics: { ...data.data.chiropractics, menu: { ...data.data.chiropractics.menu, [key]: e.target.value} } } });
                }}
                sx={size(33.333)}
            />
        ))}

        {Object.keys(data.data.chiropractics.texts).map((key: string) => (<Box component="span" key={key}>
            <Typography variant="h6" gutterBottom>{key === "presentation" ? "Présentation" : key === "symptoms" ? "Symptôme" : key === "consultation" ? "Consultation" : ""}</Typography>
            <TextField
                required multiline rows={10} label={dataList.data.chiropractics.texts.textes} value={data.data.chiropractics.texts[key].textes.join("\n")}
                onChange={(e) => {
                    setData({ ...data, data: { ...data.data, chiropractics: { ...data.data.chiropractics, texts: { ...data.data.chiropractics.texts, [key]: { ...data.data.chiropractics.texts[key], textes: e.target.value.split("\n") } } } } });
                }}
                sx={size(100)}
            />
            <TextField
                required label={dataList.data.chiropractics.texts.image} value={data.data.chiropractics.texts[key].image}
                onChange={(e) => { setData({ ...data, data: { ...data.data, chiropractics: { ...data.data.chiropractics, texts: { ...data.data.chiropractics.texts, [key]: { ...data.data.chiropractics.texts[key], image: e.target.value  } } } } })}}
                sx={size(40)}
            />
            <TextField
                required label={dataList.data.chiropractics.texts.imageAlt} value={data.data.chiropractics.texts[key].imageAlt}
                onChange={(e) => { setData({ ...data, data: { ...data.data, chiropractics: { ...data.data.chiropractics, texts: { ...data.data.chiropractics.texts, [key]: { ...data.data.chiropractics.texts[key], imageAlt: e.target.value  } } } } })}}
                sx={size(60)}
            />
            <Box component="span">
                <Typography variant="h6" gutterBottom>Sources</Typography>
                <Box sx={{ marginBottom: "1rem", width: size(20), display: "flex", justifyContent: "space-between"}}>
                    <IconButton onClick={() => {
                        setData({ ...data, data: { ...data.data, chiropractics: { ...data.data.chiropractics, texts: { ...data.data.chiropractics.texts, [key]: { ...data.data.chiropractics.texts[key], sources: [...data.data.chiropractics.texts[key].sources, { text: "", url: "" }] } } } } });
                    }} disabled={data.data.chiropractics.texts[key].sources.length >= 9} ><AddIcon /></IconButton>
                    <IconButton onClick={() => {
                        const newSources = data.data.chiropractics.texts[key].sources;
                        newSources.pop();
                        setData({ ...data, data: { ...data.data, chiropractics: { ...data.data.chiropractics, texts: { ...data.data.chiropractics.texts, [key]: { ...data.data.chiropractics.texts[key], sources: newSources } } } } });
                    }} disabled={data.data.chiropractics.texts[key].sources.length <= 0}><RemoveIcon /></IconButton>
                </Box>
                {Array.isArray(data.data.chiropractics.texts[key].sources) && data.data.chiropractics.texts[key].sources.map((source: { text: string, url: string }, index: number) => (<Box key={index}>
                    <TextField
                        required label={dataList.data.chiropractics.texts.sources.text} value={source.text.substring(2)}
                        onChange={(e) => {
                            const newSources = data.data.chiropractics.texts[key].sources;
                            newSources[index].text = numbers[index] + " " + e.target.value;
                            setData({ ...data, data: { ...data.data, chiropractics: { ...data.data.chiropractics, texts: { ...data.data.chiropractics.texts, [key]: { ...data.data.chiropractics.texts[key], sources: newSources } } } } });
                        }}
                        sx={size(50)}
                    />
                    <TextField
                        required label={dataList.data.chiropractics.texts.sources.url} value={source.url}
                        onChange={(e) => {
                            const newSources = data.data.chiropractics.texts[key].sources;
                            newSources[index].url = e.target.value;
                            setData({ ...data, data: { ...data.data, chiropractics: { ...data.data.chiropractics, texts: { ...data.data.chiropractics.texts, [key]: { ...data.data.chiropractics.texts[key], sources: newSources } } } } });
                        }}
                        sx={size(50)}
                    />
                </Box>))}
            </Box>
        </Box>))}
    </Box>)
};

export default Chiropractics;