import React, { useEffect, useState } from "react";
import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";

import "./Navbar.scss";
import SelectLang from "./SelectLang";

import Logo from "../../../assets/img/logo";

interface IProps {
    page?: string;
    dataContact: any;
    text: any;
    lang: string;
    langList: { language: string, flagUrl: string }[];
    setLang: (lang: string) => void;
}

export const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
        const offset = section.offsetTop - (window.innerHeight * 0.10);
        window.scrollTo({
            top: offset,
            behavior: 'smooth'
        });
    }
};

const Navbar = ({ page, dataContact, text, lang, langList, setLang }: IProps): React.JSX.Element => {
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [size, setSize] = useState({
        width: 0,
        height: 0,
    });

    const address = dataContact.find((data: any) => data.name === "address").data;

    useEffect((): (() => void) => {
        const handleResize = () => {
            setSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        window.addEventListener("resize", handleResize);
        return ((): void => window.removeEventListener("resize", handleResize));
    }, []);

    useEffect((): void => {
        if (size.width > 1060 && menuOpen)
            setMenuOpen(false);
    }, [size.width, menuOpen]);

    const menuToggleHandler = (): void => {
        setMenuOpen((p) => !p);
    };

    const scrollAndCloseMenu = (sectionId: string): void => {
        scrollToSection(sectionId);
        if (menuOpen)
            menuToggleHandler();
    };

    return (
        <header className="navbar">
            <div className="navbar__content">
                <div className="navbar__content__logo" onClick={() => {document.body.scrollTop = 0; document.documentElement.scrollTop = 0;}}>
                    <span className="navbar__content__logo__img"><Logo /></span>
                    <div className="navbar__content__logo__text">
                        <span className="navbar__content__logo__title">Clément de Villepin</span>
                        <span className="navbar__content__logo__subtitle">{text.global.chiropractorAt} {address.city}</span>
                    </div>
                </div>
                {page === "home" && <>
                    <nav className={`navbar__content__nav ${menuOpen && size.width < 1060 ? "isMenu" : ""}`}>
                        <ul className="navbar__content__nav__list">
                            <li className="navbar__content__nav__list__item"><a onClick={() => scrollAndCloseMenu('chiropractics')}>{text.navbar.chiropractic}</a></li>
                            <li className="navbar__content__nav__list__item"><a onClick={() => scrollAndCloseMenu('about')}>{text.navbar.about}</a></li>
                            <li className="navbar__content__nav__list__item"><a onClick={() => scrollAndCloseMenu('testimonials')}>{text.navbar.testimonials}</a></li>
                            <li className="navbar__content__nav__list__item"><a onClick={() => scrollAndCloseMenu('contact')} className="bold yellow">{text.navbar.contact}</a></li>
                        </ul>
                        <SelectLang lang={lang} langList={langList} setLang={setLang} />
                    </nav>
                </>}
                <div className="navbar__content__toggle">
                    {!menuOpen ? (
                        <span onClick={menuToggleHandler}><BiMenuAltRight /></span>
                    ) : (
                        <span onClick={menuToggleHandler}><AiOutlineClose /></span>
                    )}
                </div>
            </div>
        </header>
    );

}

export default Navbar;