import React, { useState, lazy, Suspense, useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import Navbar from "../Components/Sections/Navbar";
import Home from "../Components/Sections/Home";
import Chiropractics from "../Components/Sections/Chiropractics";
import About from "../Components/Sections/About";
import Testimonials from "../Components/Sections/Testimonials";
import Footer from "../Components/Sections/Footer";
import Contact from "../Components/Sections/Contact";
import BackToTop from "../Components/Elements/BackToTop";

import AdminLayout from "../Components/Admin/Sections/Layout";
import AdminLogin from "../Components/Admin/Sections/Login";

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const LegalNoticeComponent = lazy(() => import("../Components/Sections/LegalNotice/LegalNotice"));

export const ApiUrl = process.env.DEV ? "http://localhost:4000/" : `${window.location.protocol}//${window.location.hostname}/`;
// export const ApiUrl = "http://localhost:4000/";

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/admin" element={<Admin />} />
                <Route index path="*" element={<Public />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;

const getRefreshToken = async (refreshToken: string): Promise<string | null> => {
    const res: Response = await fetch(ApiUrl + "api/user/refresh", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "RefreshToken": refreshToken
        }
    });
    if (res.status !== 200)
        return (null);
    const data: any = await res.json();
    return (data.token as string);
}

const Admin = (): React.JSX.Element => {
    const [token, setToken] = useState(localStorage.getItem("token") || "");
    const [refreshToken, setRefreshToken] = useState(localStorage.getItem("refreshToken") || "");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (token === "" || refreshToken === "") {
            setIsLoading(false);
        } else {
            refreshTokens();
            const interval = setInterval(() => {
                refreshTokens();
            }, 1000 * 60 * 30); // 1000 ms * 60 s * 30 min

            return () => clearInterval(interval);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, refreshToken]);

    const refreshTokens = () => {
        getRefreshToken(refreshToken).then((newToken) => {
            if (newToken !== null) {
                setToken(newToken);
                localStorage.setItem("token", newToken);
            }
            setIsLoading(false);
        });
    };

    if (isLoading)
        return (<div>Loading...</div>)
    if (token === "")
        return (<AdminLogin setToken={setToken} setRefreshToken={setRefreshToken} />)
    return (<AdminLayout token={token} setToken={setToken} />)
}

const SortLangList = (list: { language: string, flagUrl: string }[], lang: string): { language: string, flagUrl: string }[] => {
    const customSort = (a: { language: string, flagUrl: string }, b: { language: string, flagUrl: string }): number => {
        if (a.language === lang)
            return (-1);
        else if (b.language === lang)
            return (1);
        else
            return (a.language.localeCompare(b.language));
    }
    return (list.sort(customSort));
}


const Public = (): React.JSX.Element => {

    const [isLoading, setIsLoading] = useState(true);
    const [dataContact, setDataContact] = useState([] as any);

    const [textLoading, setTextLoading] = useState(true);
    const [textData, setTextData] = useState({});

    const [lang, setLang] = useState<string>("fr");
    const [langList, setLangList] = useState<{ language: string, flagUrl: string }[]>([]);

    useEffect(() => {
        fetch(ApiUrl + "api/contact")
            .then((response) => response.json())
            .then((data) => setDataContact(data))
            .catch((error) => console.log(error));
        fetch(ApiUrl + "api/text/")
            .then((response) => response.json())
            .then((data) => setLangList(data))
            .then(() => setIsLoading(false))
            .catch((error) => console.log(error));
        const lang = navigator.language.split("-")[0];
        if (langList.find((item) => item.language === lang))
            setLang(lang);
        else
            setLang("fr");
        setLangList(SortLangList(langList, lang));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetch(ApiUrl + "api/text/" + lang)
            .then((response) => response.json())
            .then((data) => setTextData(data.data))
            .then(() => setTextLoading(false))
            .catch((error) => console.log(error));
        setLangList(SortLangList(langList, lang));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang]);

    const [openLegalNotice, setOpenLegalNotice] = useState(false);

    if (isLoading || dataContact.length === 0 || textLoading || Object.keys(textData).length === 0) {
        return (<Box sx={{ width: "100vw", height: "100vh", display:"flex", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress size={100}/>
      </Box>)
    }
    return (<>
        <Navbar page="home" dataContact={dataContact} text={textData} lang={lang} langList={langList} setLang={setLang} />
        <Home text={textData} dataContact={dataContact} />
        <Chiropractics text={textData} />
        <About text={textData} />
        <Testimonials text={textData} />
        <Contact dataContact={dataContact} text={textData} />
        <Footer setOpenLegalNotice={setOpenLegalNotice} dataContact={dataContact} text={textData} />
        <Suspense fallback={<div>Loading...</div>}>
            <LegalNoticeComponent openLegalNotice={openLegalNotice} dataContact={dataContact} setOpenLegalNotice={setOpenLegalNotice} text={textData} />
        </Suspense>
        <BackToTop />
    </>);
}