import React, { useState, useEffect } from "react";

import { Box, Tabs, Tab, TextField, Button, Checkbox, Typography } from "@mui/material";
import { ApiUrl } from "../../../../App";

const contactDataList = [
    {
        id: 0,
        name: "Adresse",
        storageName: "address",
        data: [{
            value: "address",
            name: "Numéro et rue"
        }, {
            value: "postalcode",
            name: "Code postal"
        }, {
            value: "city",
            name: "Ville"
        }]
    }, {
        id: 1,
        name: "Contact",
        storageName: "contact",
        data: [{
            value: "phone",
            name: "Numéro de téléphone"
        },
        {
            value: "email",
            name: "Email"
        },]
    }, {
        id: 2,
        name: "Rendez-vous",
        storageName: "appointmentApp",
        data: [{
            value: "websiteName",
            name: "Site internet"
        }, {
            value: "websiteUrl",
            name: "Lien du site"
        }]
    }, {
        id: 3,
        name: "Heures d'ouverture",
        storageName: "hours",
        data: []
    }
];

interface IHours {
    day: string,
    closed: boolean,
    am: string,
    pm: string
}

const getData = async (type: string) => {
    const res: Response = await fetch(ApiUrl + "api/contact/" + type, {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("token") as string
        }
    });
    const data = await res.json();
    return (res.status === 200 && data ? data : null);
}

const putData = async (type: string, data: any) => {
    const res = await fetch(ApiUrl + "api/contact/" + type, {
        method: "PUT",
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("token") as string,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    });
    return (res.status === 200 ? true : false);
}

const Contact = (): React.JSX.Element => {

    const [value, setValue] = useState(0);
    const [data, setData] = useState<IHours[] | any>(null);
    const [metaData, setMetaData] = useState<{ id: string, name: string }>({ id: "", name: "" });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        handleChange(null as unknown as React.SyntheticEvent, 0);
    }, []);

    const handleChange = async (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        setIsLoading(true);
        const res = await getData(contactDataList[newValue].storageName);
        if (res) {
            setData(res.data);
            setMetaData({ id: res.id, name: res.name });
        }
        setIsLoading(false);
    };

    const handleSubmit = async () => {
        const res = await putData(contactDataList[value].storageName, { id: metaData.id, name: metaData.name, data: data });
        alert(res ? "Modifications enregistrées" : "Erreur lors de la modification");
    }

    if (isLoading || data === null)
        return (<p>Loading...</p>)
    return (<Box sx={{ width: "100%" }}>
        <h1>Contact</h1>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="Menu">
                {contactDataList.map((contactData) => {
                    return (<Tab label={contactData.name} id={"ContactListData" + contactData.id} key={"ContactListData" + contactData.id} />)
                })}
            </Tabs>
        </Box>
        {isLoading ? <p>Loading...</p> :
            <Box component="form" sx={{ marginTop: "2rem" }} >
                {contactDataList[value].data.map((contactData, index) => {
                    return (
                        <TextField
                            key={"ContactListData__Form" + value + "_" + index}
                            required
                            label={contactData.name}
                            value={data[contactData.value]}
                            onChange={(e) => {
                                setData({ ...data, [contactData.value]: e.target.value })
                            }}
                            sx={{ margin: "0 2rem 1rem", width: "calc(50% - 4rem)" }}
                        />
                    )
                })}
                {contactDataList[value].storageName === "hours" ? <>
                    {["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"].map((day, index) => {
                        return (<Box key={"ContactListData__Form" + value + "_" + index} sx={{ display: "flow", margin: "0 2rem 1rem" }}>
                            <Typography sx={{ margin: "2rem 4rem 1rem" }} variant="h5">{day}</Typography>
                            <Box sx={{ display: "flex", alignItems: "center", margin: "0 2rem 1rem" }}>
                                <p>Fermé ?</p>
                                <Checkbox
                                    checked={data[index].closed}
                                    onChange={(e) => {
                                        const newData = [...data];
                                        newData[index].closed = e.target.checked;
                                        setData(newData);
                                    }}
                                    sx={{ margin: "0 1rem 0 0" }}
                                />
                                <TextField
                                    label="Matin"
                                    value={data[index].am}
                                    onChange={(e) => {
                                        const newData = [...data];
                                        newData[index].am = e.target.value;
                                        setData(newData);
                                    }}
                                    sx={{ margin: "0 1rem 0 0", width: "40%" }}
                                />
                                <TextField
                                    label="Après-midi"
                                    value={data[index].pm}
                                    onChange={(e) => {
                                        const newData = [...data];
                                        newData[index].pm = e.target.value;
                                        setData(newData);
                                    }}
                                    sx={{ margin: "0 1rem 0 0", width: "40%" }}
                                />

                            </Box>
                        </Box>)
                    })}
                </> : null}
                <br />
                <Box component="div" sx={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
                    <Button onClick={handleSubmit} type="button" variant="contained" sx={{
                        margin: "0 2rem",
                        width: "10rem",
                    }}>Valider</Button>
                </Box>
            </Box>
        }
    </Box>)
}

export default Contact;