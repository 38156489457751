import React from "react";

import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { MenuButton as BaseMenuButton } from '@mui/base/MenuButton';
import { MenuItem as BaseMenuItem } from '@mui/base/MenuItem';
import { styled } from '@mui/system';

interface IProps {
    lang: string;
    langList: { language: string, flagUrl: string }[];
    setLang: (lang: string) => void;
}

const SelectLang = ({lang, langList, setLang}: IProps): React.JSX.Element => {

    const Listbox = styled('ul')(() =>
        `
        padding: 7px;
        border-radius: 7px;
        background: var(--background);
        border: 1px solid #d0d7de;
        box-shadow: 0px 4px 30px #d0d7de;
        `,
    );

    const MenuItem = styled(BaseMenuItem)(() =>
        `
        list-style: none;
        padding: 7px;
        border-radius: 7px;
        cursor: pointer;
        .flag {
            height: 20px;
            width: 30px;
            object-fit: cover;
        }
        &:hover {
            background-color: #eaeef2;
        }
        `,
    );

    const MenuButton = styled(BaseMenuButton)(() =>
        `
        background: rgba(0, 0, 0, 0);
        border: none;
        `,
    );

    const createHandleMenuClick = (menuItem: string) => {
        return () => {
            setLang(menuItem);
        };
    };

    return (
        <Dropdown>
            <MenuButton><img src={langList.find((item: { language: string, flagUrl: string }) => item.language === lang)?.flagUrl} className="flag" alt={"flag_" + lang} /></MenuButton>
            <Menu slots={{ listbox: Listbox }} style={{ zIndex: "999" }}>
                {langList.filter((menuItem: { language: string, flagUrl: string }) => menuItem.language !== lang).map((menuItem: { language: string, flagUrl: string }) => {
                    return (
                        <MenuItem onClick={createHandleMenuClick(menuItem.language)} key={"select_item_" + menuItem}><img src={menuItem.flagUrl} className="flag" alt={"select_flag_" + menuItem}/></MenuItem>
                    )
                })}
            </Menu>
        </Dropdown>
    )
}

export default SelectLang;