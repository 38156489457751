import React from "react";

import { Box, TextField, Typography } from "@mui/material";

interface IProps {
    dataList: any;
    data: any;
    size: (size: number) => Object;
    setData: (data: Object) => void;
}

const About = ({ dataList, data, size, setData }: IProps): React.JSX.Element => {
    return (<Box sx={{ width: '100%' }}>
        <Typography variant="h5" gutterBottom>A propos</Typography>
        <TextField
            required label={dataList.data.about.title} value={data.data.about.title}
            onChange={(e) => { setData({ ...data, data: { ...data.data, about: { ...data.data.about, title: e.target.value } } }) }}
            sx={size(100)}
        />
        {Object.keys(data.data.about).map((key: string) => (<Box component="span" key={key}>{key === "title" ? "" : <>
            <Typography variant="h6" gutterBottom>{key === "text1" ? "Texte 1" : key === "text2" ? "Texte 2" : ""}</Typography>
            <TextField
                required label={dataList.data.about.data.title} value={data.data.about[key].title}
                onChange={(e) => { setData({ ...data, data: { ...data.data, about: { ...data.data.about, [key]: { ...data.data.about[key], ...data.data.about, title: e.target.value } } } }) }}
                sx={size(50)}
            />
            <TextField
                required label={dataList.data.about.data.subtitle} value={data.data.about[key].subtitle}
                onChange={(e) => { setData({ ...data, data: { ...data.data, about: { ...data.data.about, [key]: { ...data.data.about[key], subtitle: e.target.value } } } }) }}
                sx={size(50)}
            />
            <TextField
                required multiline rows={5} label={dataList.data.about.data.texts} value={data.data.about[key].texts.join("\n")}
                onChange={(e) => { setData({ ...data, data: { ...data.data, about: { ...data.data.about, [key]: { ...data.data.about[key], texts: e.target.value.split("\n") } } } }); }}
                sx={size(100)}
            />
        </>}</Box>))}
    </Box>)
};

export default About;