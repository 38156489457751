import React from "react";

import "./Footer.scss";

import Logo from "../../../assets/img/logo";
import { BsTelephoneFill } from "react-icons/bs";
import { BiSolidHome } from "react-icons/bi";
import { AiFillHeart } from "react-icons/ai";

interface FooterProps {
    setOpenLegalNotice: (open: boolean) => void;
    dataContact: any;
    text: any;
}

const Footer = ({setOpenLegalNotice, dataContact, text}:FooterProps): React.JSX.Element => {
    const year = new Date().getFullYear();

    const address = dataContact.find((data: any) => data.name === "address").data;
    const contact = dataContact.find((data: any) => data.name === "contact").data;

    const credits = text.footer.credits.includes("|") ? text.footer.credits.split("|") : text.footer.credits;

    return (
        <footer className="footer">
            <div className="footer__container">
                <div className="footer__container__left">
                    <div className="footer__container__left__logo"><Logo /></div>
                    <div className="footer__container__left__text">
                        <span className="footer__container__left__text__title">Clément de Villepin</span>
                        <span className="footer__container__left__text__subtitle">{text.global.chiropractorAt} {address.city}</span>
                    </div>
                </div>
                <div className="footer__container__middle">
                    <span className="footer__container__middle__title">{text.footer.linksTitle}</span>
                    <div className="footer__container__middle__links">
                            <a href={text.footer.links[0].url} className="footer__container__middle__links__link" target="_blank" rel="noreferrer">{text.footer.links[0].name}</a>
                            <a href={text.footer.links[1].url} className="footer__container__middle__links__link" target="_blank" rel="noreferrer">{text.footer.links[1].name}</a>
                            <a href="#" className="footer__container__middle__links__link" onClick={() => {setOpenLegalNotice(true)}}>{text.footer.legalTitle}</a>
                    </div>
                </div>
                <div className="footer__container__right">
                    <span className="footer__container__right__title">{text.footer.contactTitle}</span>
                    <div className="footer__container__right__contact">
                            <span className="footer__container__right__contact__item"><BiSolidHome /> : {address.address + " " + address.postalcode + " " + address.city}</span>
                            <span className="footer__container__right__contact__item"><BsTelephoneFill /> : {contact.phone} </span>
                    </div>
                </div>
            </div>
            <div className="footer__bottom">
                <span className="footer__bottom__text">© {year} - {
                    typeof credits === "string" ? credits : credits.map((credit: string, index: number) => {
                        return (
                            <span key={index}>{credit} {index !== credits.length - 1 ? <span className="pipe"/> : ""}</span>
                        )
                    })
                } <span className="footer__bottom__text__icon"><AiFillHeart /></span> {text.footer.creditBy} <a href="https://villepin-it.com" target="_blank" rel="noreferrer" className="footer__bottom__text__link">Villepin IT</a></span>
            </div>
        </footer>
    )
};

export default Footer;