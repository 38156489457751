import React from "react";

import { Box, TextField, Typography } from "@mui/material";

interface IProps {
    dataList: any;
    data: any;
    size: (size: number) => Object;
    setData: (data: Object) => void;
}

const Contact = ({ dataList, data, size, setData }: IProps): React.JSX.Element => {
    return (<Box sx={{ width: '100%' }}>
        <Typography variant="h5" gutterBottom>Contact</Typography>
        <TextField
            required label={dataList.data.contact.title} value={data.data.contact.title}
            onChange={(e) => { setData({ ...data, data: { ...data.data, contact: { ...data.data.contact, title: e.target.value } } }) }}
            sx={size(100)}
        />

        <TextField
            required label={dataList.data.contact.office.title} value={data.data.contact.office.title}
            onChange={(e) => {
                setData({ ...data, data: { ...data.data, contact: { ...data.data.contact, office: { ...data.data.contact.office, title: e.target.value } } } });
            }} sx={size(33.333)}
        />
        <TextField
            required label={dataList.data.contact.office.handicapped} value={data.data.contact.office.handicapped}
            onChange={(e) => {
                setData({ ...data, data: { ...data.data, contact: { ...data.data.contact, office: { ...data.data.contact.office, handicapped: e.target.value } } } });
            }} sx={size(33.333)}
        />
        <TextField
            required label={dataList.data.contact.office.parking} value={data.data.contact.office.parking}
            onChange={(e) => {
                setData({ ...data, data: { ...data.data, contact: { ...data.data.contact, office: { ...data.data.contact.office, parking: e.target.value } } } });
            }} sx={size(33.333)}
        />

        <TextField
            required label={dataList.data.contact.appointment.title} value={data.data.contact.appointment.title}
            onChange={(e) => {
                setData({ ...data, data: { ...data.data, contact: { ...data.data.contact, appointment: { ...data.data.contact.appointment, title: e.target.value } } } });
            }} sx={size(50)}
        />
        <TextField
            required label={dataList.data.contact.appointment.text} value={data.data.contact.appointment.text}
            onChange={(e) => {
                setData({ ...data, data: { ...data.data, contact: { ...data.data.contact, appointment: { ...data.data.contact.appointment, text: e.target.value } } } });
            }} sx={size(50)}
        />

        <TextField
            required label={dataList.data.contact.hours.title} value={data.data.contact.hours.title}
            onChange={(e) => {
                setData({ ...data, data: { ...data.data, contact: { ...data.data.contact, hours: { ...data.data.contact.hours, title: e.target.value } } } });
            }} sx={size(33.333)}
        />
        <TextField
            required label={dataList.data.contact.hours.onlyWithAppointment} value={data.data.contact.hours.onlyWithAppointment}
            onChange={(e) => {
                setData({ ...data, data: { ...data.data, contact: { ...data.data.contact, hours: { ...data.data.contact.hours, onlyWithAppointment: e.target.value } } } });
            }} sx={size(33.333)}
        />
        <TextField
            required label={dataList.data.contact.hours.closed} value={data.data.contact.hours.closed}
            onChange={(e) => {
                setData({ ...data, data: { ...data.data, contact: { ...data.data.contact, hours: { ...data.data.contact.hours, closed: e.target.value } } } });
            }} sx={size(33.333)}
        />
        <Typography variant="h6" gutterBottom>Jours</Typography>
        {/* {data.data.contact.hours.days.map((day: string, index: number) => (
            <TextField
                key={index} required label={dataList.data.contact.hours.days[index]} value={day}
                onChange={(e) => {
                    const newDays = [...data.data.contact.hours.days];
                    newDays[index] = e.target.value;
                    setData({ ...data, data: { ...data.data, contact: { ...data.data.contact, hours: { ...data.data.contact.hours, days: newDays } } } });
                }}
                sx={size(25)}
            />
        ))} */}
    </Box>)
};

export default Contact;