import React, {useState} from "react";

import "./About.scss";

interface IProps {
    text: any;
}

const About = ({text}: IProps): React.JSX.Element => {
    const [profil, setProfil] = useState<boolean>(false);
    const [office, setOffice] = useState<boolean>(false);

    const handleClickArrow = (id: number) => {
        switch (id) {
            case 0:
                setProfil(!profil);
                break;
            case 1:
                setOffice(!office);
                break;
            default:
                break;
        }
    }

    return (<section className="about" id="about">
        <div className="about__container">
            <div className="about__container__title">{text.about.title}</div>
            <div className="about__container__content">
                <div className="about__container__content__box" id="about_profil">
                    <div className={"about__container__content__box__data " + (profil && "deployed")}>
                        <span className="about__container__content__box__data__arrow" onClick={() => handleClickArrow(0)}>⮟</span>
                        <span className="about__container__content__box__data__title">{text.about.text1.title}</span>
                        <span className="about__container__content__box__data__subtitle">{text.about.text1.subtitle}</span>
                        <span className="about__container__content__box__data__text">{text.about.text1.texts.map((t: String, i: number) => {return(<span key={"Text about text2" + i}>{t}<br/></span>)})}</span>
                    </div>
                </div>
                <div className="about__container__content__box" id="about_office">
                <div className={"about__container__content__box__data " + (office && "deployed")}>
                        <span className="about__container__content__box__data__arrow" onClick={() => handleClickArrow(1)}>⮟</span>
                        <span className="about__container__content__box__data__title">{text.about.text2.title}</span>
                        <span className="about__container__content__box__data__subtitle">{text.about.text2.subtitle}</span>
                        <span className="about__container__content__box__data__text">{text.about.text2.texts.map((t: String, i: number) => {return(<span key={"Text about text2" + i}>{t}<br/></span>)})}</span>
                    </div>
                </div>
            </div>
        </div>
    </section>);
}

export default About;