import React from "react";

import { Box, TextField, Typography } from "@mui/material";

interface IProps {
    dataList: any;
    data: any;
    size: (size: number) => Object;
    setData: (data: Object) => void;
}

const Footer = ({ dataList, data, size, setData }: IProps): React.JSX.Element => {
    return (<Box sx={{ width: '100%' }}>
        <Typography variant="h5" gutterBottom>Footer</Typography>

        <TextField
                required label={dataList.data.footer.legalTitle} value={data.data.footer.legalTitle}
                onChange={(e) => {
                    setData({ ...data, data: { ...data.data, footer: { ...data.data.footer, legalTitle: e.target.value } } });
                }} sx={size(33.333)}
        />
        <TextField
                required label={dataList.data.footer.linksTitle} value={data.data.footer.linksTitle}
                onChange={(e) => {
                    setData({ ...data, data: { ...data.data, footer: { ...data.data.footer, linksTitle: e.target.value } } });
                }} sx={size(33.333)}
        />
        <TextField
                required label={dataList.data.footer.contactTitle} value={data.data.footer.contactTitle}
                onChange={(e) => {
                    setData({ ...data, data: { ...data.data, footer: { ...data.data.footer, contactTitle: e.target.value } } });
                }} sx={size(33.333)}
        />
        <TextField
                required label={dataList.data.footer.credits} value={data.data.footer.credits}
                onChange={(e) => {
                    setData({ ...data, data: { ...data.data, footer: { ...data.data.footer, credits: e.target.value } } });
                }} sx={size(66.666)}
        />
        <TextField
                required label={dataList.data.footer.creditBy} value={data.data.footer.creditBy}
                onChange={(e) => {
                    setData({ ...data, data: { ...data.data, footer: { ...data.data.footer, creditBy: e.target.value } } });
                }} sx={size(33.333)}
        />
        <Typography variant="h6" gutterBottom>Liens</Typography>

        {data.data.footer.links.map((link: { name: string, url: string }, index: number) => (<Box key={index}>
            <TextField
                required label={dataList.data.footer.links.name} value={link.name}
                onChange={(e) => {
                    const newSources = data.data.footer.links;
                    newSources[index].name = e.target.value;
                    setData({ ...data, data: { ...data.data, footer: { ...data.data.footer, links: newSources } } });
                }}
                sx={size(50)}
            />
            <TextField
                required label={dataList.data.footer.links.url} value={link.url}
                onChange={(e) => {
                    const newSources = data.data.footer.links;
                    newSources[index].url = e.target.value;
                    setData({ ...data, data: { ...data.data, footer: { ...data.data.footer, links: newSources } } });
                }}
                sx={size(50)}
            />
        </Box>))}
    </Box>)
};

export default Footer;