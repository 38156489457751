import { TestimonialsData, ApiResult as GetApiResult } from "./Testimonials";
import { ApiUrl } from "../../../../App";

const getData = async (page?: number, items?: number): Promise<any> => {
    const query: string = "?page=" + (page ? page : "0") + "&items=" + (items ? items : "6");

    const res: Response = await fetch(ApiUrl + "api/testimonials/" + query, {
        method: "GET",
        headers: {
            authorization: "Bearer " + localStorage.getItem("token"),
        },
    });
    const data = await res.json();
    const formatedData: GetApiResult = {
        total: data.total as number,
        testimonials: data.testimonials.map((testimonial: any) => {
            return {
                id: testimonial.id as string,
                name: testimonial.name as string,
                date: new Date(testimonial.date),
                stars: parseFloat(testimonial.stars.replace(',', '.')),
                comment: testimonial.comment as string,
            };
        }) as TestimonialsData[],
    };
    return (res.status === 200 && data ? formatedData : null);
};

const deleteData = async (id: string): Promise<boolean> => {
    const res: Response = await fetch(ApiUrl + "api/testimonials/" + id, {
        method: "DELETE",
        headers: {
            authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
        },
    });
    return (res.status === 200);
};

const convertDatetoString = (date: Date): string => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return (month + "/" + day + "/" + year);
};

const newData = async (data: TestimonialsData): Promise<boolean> => {
    const res: Response = await fetch(ApiUrl + "api/testimonials/", {
        method: "POST",
        headers: {
            authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            date: convertDatetoString(data.date),
            name: data.name,
            stars: data.stars,
            comment: data.comment,
        }),
    });
    return (res.status === 200);
};

const updateData = async (data: TestimonialsData): Promise<boolean> => {
    const res: Response = await fetch(ApiUrl + "api/testimonials/" + data.id, {
        method: "PUT",
        headers: {
            authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            date: convertDatetoString(data.date),
            name: data.name,
            stars: data.stars,
            comment: data.comment,
        }),
    });
    return (res.status === 200);
};

export { getData, deleteData, newData, updateData };