import React, { useState } from "react";

import "./Chiropractics.scss";

interface IProps {
    text: any;
}

const Chiropractics = ({text}: IProps): React.JSX.Element => {
    const [page, setPage] = useState<0 | 1 | 2>(0);

    return (
        <section className="chiropractics" id="chiropractics">
            <div className="chiropractics__container">
            <div className="chiropractics__container__title">{text.chiropractics.title}</div>

                <div className="chiropractics__container__content">
                    <div className="chiropractics__container__content__menu">
                        <span className={"chiropractics__container__content__menu__item " + (page === 0 && "active")} onClick={() => setPage(0)}><h3>{text.chiropractics.menu.presentation}</h3></span>
                        <span className={"chiropractics__container__content__menu__item " + (page === 1 && "active")} onClick={() => setPage(1)}><h3>{text.chiropractics.menu.symptoms}</h3></span>
                        <span className={"chiropractics__container__content__menu__item " + (page === 2 && "active")} onClick={() => setPage(2)}><h3>{text.chiropractics.menu.consultation}</h3></span>
                    </div>
                    {page === 0 && (<>
                        <div className="chiropractics__container__content__data">
                            <p className="chiropractics__container__content__data__text">
                                {text.chiropractics.texts.presentation.textes.map((text: string, index: number) => {return (<span key={"text chiropractics texts presentation texts" + index}>{text}<br/></span>)})}
                            </p>
                { /*   
                            <img className="chiropractics__container__content__data__image" src={text.chiropractics.texts.presentation.image} alt={text.chiropractics.texts.presentation.imageAlt}/>
                */ }
                            <iframe title="Video introducting to chiropractic care" className="chiropractics__container__content__data__image" src="https://www.youtube-nocookie.com/embed/K8N6JPTzpBs" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                            <div className="chiropractics__container__content__data__legend">
                                {text.chiropractics.texts.presentation.sources.map((legend: {text: string, url: string}, index: number) => {return (<a key={"text chiropractics texts presentation sources" + index} className="chiropractics__container__content__data__legend__item" href={legend.url} target="_blank" rel="noreferrer">{legend.text}</a>)})}
                            </div>
                        </div>
                    </>)}
                    {page === 1 && (<>
                        <div className="chiropractics__container__content__data">
                            <p className="chiropractics__container__content__data__text">
                                {text.chiropractics.texts.symptoms.textes.map((text: string, index: number) => {return (<span key={"text chiropractics texts symptoms texts" + index}>{text}<br/></span>)})}
                            </p>
                           {/* {text.chiropractics.texts.symptoms.image != "//" ? 
                            <img className="chiropractics__container__content__data__image" src={text.chiropractics.texts.symptoms.image} alt={text.chiropractics.texts.symptoms.imageAlt}/>
                            : <></>}  */}
                            <div className="chiropractics__container__content__data__legend">
                                {text.chiropractics.texts.symptoms.sources.map((legend: {text: string, url: string}, index: number) => {return (<a key={"text chiropractics texts symptoms sources" + index} className="chiropractics__container__content__data__legend__item" href={legend.url} target="_blank" rel="noreferrer">{legend.text}</a>)})}
                            </div>
                        </div>
                    </>)}
                    {page === 2 && (<>
                        <div className="chiropractics__container__content__data">
                            <p className="chiropractics__container__content__data__text">
                                {text.chiropractics.texts.consultation.textes.map((text: string, index: number) => {return (<span key={"text chiropractics texts consultation texts" + index}>{text}<br/></span>)})}
                            </p>
                            {/* {text.chiropractics.texts.consultation.image != "//" ? 
                            <img className="chiropractics__container__content__data__image" src={text.chiropractics.texts.consultation.image} alt={text.chiropractics.texts.consultation.imageAlt}/>
                            : <></>}  */}
                            <div className="chiropractics__container__content__data__legend">
                                {text.chiropractics.texts.consultation.sources.map((legend: {text: string, url: string}, index: number) => {return (<a key={"text chiropractics texts consultation sources" + index} className="chiropractics__container__content__data__legend__item" href={legend.url} target="_blank" rel="noreferrer">{legend.text}</a>)})}
                            </div>
                        </div>
                    </>)}
                </div>
            </div>
        </section>
    )
};

export default Chiropractics;