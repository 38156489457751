import React from "react";

import { Box, TextField, Typography } from "@mui/material";

interface IProps {
    dataList: any;
    data: any;
    size: (size: number) => Object;
    setData: (data: Object) => void;
}

const Home = ({ dataList, data, size, setData }: IProps): React.JSX.Element => {
    return (<Box sx={{ width: '100%' }}>
        <Typography variant="h5" gutterBottom>Home</Typography>
        <TextField
            required label={dataList.data.home.public} value={data.data.home.public}
            onChange={(e) => { setData({ ...data, data: { ...data.data, home: { ...data.data.home, public: e.target.value } } }) }}
            sx={size(60)}
        />
        <TextField
            required label={dataList.data.home.button} value={data.data.home.button}
            onChange={(e) => { setData({ ...data, data: { ...data.data, home: { ...data.data.home, button: e.target.value } } }) }}
            sx={size(40)}
        />
    </Box>)
};

export default Home;