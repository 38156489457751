import React, { useEffect, useState } from "react";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import { ApiUrl } from "../../../App";

import "./Testimonials.scss";
import Button from "../../Button";

interface ITestimonials {
    id: number;
    name: string;
    stars: number;
    comment: string;
}

interface IProps {
    text: any;
}

const getData = async (page?: number): Promise<{total: number, testimonials: ITestimonials[]} | null> => {
    const query: string = "?page=" + (page ? page : "0") + "&items=6";
    const res: Response = await fetch(ApiUrl + "api/testimonials/" + query, {
        method: "GET",
        headers: {
            authorization: "Bearer " + localStorage.getItem("token"),
        },
    });
    const data = await res.json();
    const formatedData: {total: number, testimonials: ITestimonials[]} = {
        total: data.total as number,
        testimonials: data.testimonials.map((testimonial: any) => {
            return {
                id: testimonial.id as string,
                name: testimonial.name as string,
                stars: parseFloat(testimonial.stars.replace(',', '.')),
                comment: testimonial.comment as string,
            };
        }) as ITestimonials[],
    };
    return (res.status === 200 && data ? formatedData : null);

}

const Testimonials = ({ text }: IProps): React.JSX.Element => {

    const [data, setData] = useState<ITestimonials[]>();
    const [total, setTotal] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (data === undefined) {
            getData().then((res) => {
                if (res) {
                    setData(res.testimonials)
                    setTotal(res.total)
                };
                setIsLoading(false);
            });
        }
    }, [data]);

    // NEED -> Add pagination for multiple pages

    return (<>
        { !isLoading && <section className="testimonials" id="testimonials">
            <div className="testimonials__container">
                <h2 className="testimonials__title">{text.testimonials.title}</h2>
                <div className="testimonials__cardlist">
                    {data && data.map((testimonial: ITestimonials) => (
                        <div className="testimonials__card" key={testimonial.id}>
                            <div className="testimonials__card__header">
                                <p className="testimonials__card__header__name">{testimonial.name}</p>
                                <p className="testimonials__card__header__stars">
                                    {[...Array(5)].map((star: number, index: number) => ((index > testimonial.stars) ? <FaRegStar key={index} /> : (testimonial.stars % 1 !== 0 && index === Math.floor(testimonial.stars)) ? <FaStarHalfAlt key={index} /> : <FaStar key={index} />))}
                                </p>
                            </div>
                            <p className="testimonials__card__comment">{testimonial.comment}</p>
                        </div>
                    ))}
                </div>
                <Button text={text.testimonials.button} className="testimonials__button" link={text.testimonials.link} newWindow colored/>
            </div>
        </section>}
    </>);
}

export default Testimonials;