import React from "react";

import { Box, TextField, Typography } from "@mui/material";

interface IProps {
    dataList: any;
    data: any;
    size: (size: number) => Object;
    setData: (data: Object) => void;
}

const Configuration = ({ dataList, data, size, setData }: IProps): React.JSX.Element => {
    return (<Box sx={{ width: '100%' }}>
        <Typography variant="h5" gutterBottom>Configuration</Typography>
        <TextField
            disabled label={dataList.id} value={data.id}
            sx={size(30)}
        />
        <TextField
            disabled label={dataList.language} value={data.language}
            sx={size(15)}
        />
        <TextField
            required label={dataList.name} value={data.name}
            onChange={(e) => { setData({ ...data, name: e.target.value }) }}
            sx={size(20)}
        />
        <TextField
            required label={dataList.flagUrl} value={data.flagUrl}
            onChange={(e) => { setData({ ...data, flagUrl: e.target.value }) }}
            sx={size(35)}
        />
    </Box>)
};

export default Configuration;