import React from "react";

import { Box, TextField, Typography } from "@mui/material";

interface IProps {
    dataList: any;
    data: any;
    size: (size: number) => Object;
    setData: (data: Object) => void;
}

const Testimonials = ({ dataList, data, size, setData }: IProps): React.JSX.Element => {
    return (<Box sx={{ width: '100%' }}>
        <Typography variant="h5" gutterBottom>Témoignages</Typography>
        <TextField
            required label={dataList.data.testimonials.title} value={data.data.testimonials.title}
            onChange={(e) => { setData({ ...data, data: { ...data.data, testimonials: { ...data.data.testimonials, title: e.target.value } } }) }}
            sx={size(100)}
        />
    </Box>)
};

export default Testimonials;