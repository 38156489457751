import React from "react";

import { Box, TextField, Typography } from "@mui/material";

interface IProps {
    dataList: any;
    data: any;
    size: (size: number) => Object;
    setData: (data: Object) => void;
}

const Global = ({ dataList, data, size, setData }: IProps): React.JSX.Element => {
    return (<Box sx={{ width: '100%' }}>
        <Typography variant="h5" gutterBottom>Global</Typography>
        <TextField
            required label={dataList.data.global.chiropractorAt} value={data.data.global.chiropractorAt}
            onChange={(e) => { setData({ ...data, data: { ...data.data, global: { ...data.data.global, chiropractorAt: e.target.value } } }) }}
            sx={size(50)}
        />
    </Box>)
};

export default Global;