import React, { useState, useEffect } from "react";

import { Box, Tabs, Tab, Button } from "@mui/material";

import dataList from "./dataList.json";
import { ApiUrl } from "../../../../App";

import Configuration from "./Section/Configuration";
import Global from "./Section/Global";
import Navbar from "./Section/Navbar";
import Home from "./Section/Home";
import Chiropractics from "./Section/Chiropractics";
import About from "./Section/About";
import Testimonials from "./Section/Testimonials";
import Contact from "./Section/Contact";
import Footer from "./Section/Footer";

const getData = async (language: string) => {
    const res: Response = await fetch(ApiUrl + "api/text/" + language, {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("token") as string
        }
    });
    const data = await res.json();
    return (res.status === 200 && data ? data : null);
}

const putData = async (data: any) => {
    const res = await fetch(ApiUrl + "api/text/" + data.language, {
        method: "PUT",
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("token") as string,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    });
    return (res.status === 200 ? true : false);
}



const Language = (): React.JSX.Element => {

    const [value, setValue] = useState(0);
    const [data, setData] = useState<any>();
    const [isLoading, setIsLoading] = useState(true);
    const [langList, setLangList] = useState<{ language: string, flagUrl: string }[]>([]);

    useEffect(() => {
        fetch(ApiUrl + "api/text/")
            .then((response) => response.json())
            .then(async (d) => {
                setLangList(d);
                const res = await getData("fr");
                if (res) setData(res);
                setIsLoading(false);
            })
            .catch((error) => console.log(error));
    }, []);

    const handleChange = async (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        setIsLoading(true);
        const res = await getData(langList[newValue].language);
        if (res)
            setData(res);
        setIsLoading(false);
    };

    const handleSubmit = async () => {
        if (langList.find((lang) => lang.language === data.language) === undefined) {

        } else {
            const res = await putData(data);
            alert(res ? "Modifications enregistrées" : "Erreur lors de la modification");
        }
    }

    const size = (size: number) => (
        { margin: "0 0.5rem 1rem", width: "calc(" + size + "% - 1rem)" }
    )

    const SubmitButton = () => {
        return (<Box component="div" sx={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
            <Button onClick={handleSubmit} type="button" variant="contained" sx={{
                margin: "0 2rem",
                width: "10rem",
            }}>Valider</Button>
        </Box>)
    }

    return (<Box sx={{ width: "100%" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", verticalAlign: "middle", alignItems: "center" }}>
            <h1>Langues</h1>
            <Button type="button" size="small" variant="contained" sx={{ height: "3rem" }} onClick={() => {

            }} disabled>Nouvelle langue</Button>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="Menu">
                {langList.map((lang: { language: string, flagUrl: string }) => {
                    return (<Tab label={<img src={lang.flagUrl} className="flag" alt={"select_flag_" + lang.language} />} id={"LangList" + lang.language} key={"LangList" + lang.language} />)
                })}
            </Tabs>
        </Box>
        {isLoading ? <p>Loading...</p> :
            <Box component="form" sx={{ marginTop: "2rem" }} >
                <Configuration dataList={dataList} data={data} setData={setData} size={size} />
                <Global dataList={dataList} data={data} setData={setData} size={size} />
                <Navbar dataList={dataList} data={data} setData={setData} size={size} />
                <Home dataList={dataList} data={data} setData={setData} size={size} />
                <SubmitButton />
                <Chiropractics dataList={dataList} data={data} setData={setData} size={size} />
                <SubmitButton />
                <About dataList={dataList} data={data} setData={setData} size={size} />
                <SubmitButton />
                <Testimonials dataList={dataList} data={data} setData={setData} size={size} />
                <SubmitButton />
                <Contact dataList={dataList} data={data} setData={setData} size={size} />
                <SubmitButton />
                <Footer dataList={dataList} data={data} setData={setData} size={size} />
                <SubmitButton />
            </Box>
        }
    </Box>)
}

export default Language;