import React from "react";

import "./Contact.scss";

import { BsTelephoneFill } from "react-icons/bs";
import { BiSolidHome } from "react-icons/bi";

import Button from "../../Button/Button";

interface IOpeningHours {
    day: string;
    closed?: boolean;
    am?: string;
    pm?: string;
}

interface ContactProps {
    dataContact: any;
    text: any;
}

const Contact = ({dataContact, text}: ContactProps) => {

    const address = dataContact.find((data: any) => data.name === "address").data;
    const contact = dataContact.find((data: any) => data.name === "contact").data;
    const appointmentApp = dataContact.find((data: any) => data.name === "appointmentApp").data;
    const hours = dataContact.find((data: any) => data.name === "hours").data;

    const openingHours: IOpeningHours[] = hours;

    const renderOpeningHours = () => {
        return openingHours.map((openingHour, index) => (
            <span key={index} className="contact__container__content__box__data__text">
                <div className="contact__container__content__box__data__table">
                    <div className="contact__container__content__box__data__table__col-time">{text.contact.hours.days[index]}</div>
                    <div className="contact__container__content__box__data__table__col-time">
                        {openingHour.closed ? text.contact.hours.closed : `${openingHour.am || ""} ${openingHour.pm || ""}`}
                    </div>
                </div>
            </span>
        ));
    };

    return (
        <section className="contact" id="contact">
            <div className="contact__container">
                <span className="contact__container__title">{text.contact.title}</span>
                <div className="contact__container__map">
                <iframe title="Google map localisation" className="contact__container__map__img" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2670.1534858988607!2d2.7299515767392366!3d47.991420861324485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48015378fdcc5565%3A0x31d2c05640845f5c!2sCl%C3%A9ment%20de%20Villepin!5e0!3m2!1sfr!2sfr!4v1716207015920!5m2!1sfr!2sfr" loading="lazy"></iframe>
{ /* 
                    <div className="contact__container__map__box">
                        <span className="contact__container__map__box__title">{text.contact.office.title}</span>
                        <div className="contact__container__map__box__data">
                            <span className="contact__container__content__box__data__text">
                                <div className="contact__container__content__box__data__table">
                                    <div className="contact__container__content__box__data__table__col-addr"><BiSolidHome /></div>
                                    <div className="contact__container__content__box__data__table__col-addr">{address.address}<br />{address.postalcode + " " + address.city}</div>
                                </div>
                            </span>
                            <span className="contact__container__content__box__data__text">
                                <div className="contact__container__content__box__data__table">
                                    <div className="contact__container__content__box__data__table__col-addr"><BsTelephoneFill /></div>
                                    <div className="contact__container__content__box__data__table__col-addr">{phone.phone}</div>
                                </div>
                            </span>
                            <span className="contact__container__content__box__data__text bold">{text.contact.office.handicapped}</span>
                            <span className="contact__container__content__box__data__text bold">{text.contact.office.parking}</span>
                        </div>
                    </div>
*/} 
                </div>
                <div className="contact__container__content">
                    <div className="contact__container__content__box">
                        <span className="contact__container__content__box__title">{text.contact.office.title}</span>
                        <div className="contact__container__content__box__data">
                        <div className="contact__container__content__box__data__table__cab">
                            <div className="contact__container__content__box__data__table__cab-item">
                            <span className="contact__container__content__box__data__text">
                                <div className="contact__container__content__box__data__table">
                                    <div className="contact__container__content__box__data__table__col-addr"><BiSolidHome /></div>
                                    <div className="contact__container__content__box__data__table__col-addr">{address.address}<br />{address.postalcode + " " + address.city}</div>
                                </div>
                            </span>
                            <span className="contact__container__content__box__data__text">
                                <div className="contact__container__content__box__data__table">
                                    <div className="contact__container__content__box__data__table__col-addr"><BsTelephoneFill /></div>
                                    <div className="contact__container__content__box__data__table__col-addr">{contact.phone}</div>
                                </div>
                            </span>
                            </div>
                            <div className="contact__container__content__box__data__table__cab-item">
                                <span className="contact__container__content__box__data__text bold">{text.contact.office.handicapped}</span>
                                <span className="contact__container__content__box__data__text bold">{text.contact.office.parking}</span>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="contact__container__content__box">
                        <span className="contact__container__content__box__title">{text.contact.appointment.title}</span>
                        <div className="contact__container__content__box__data">
                            <span className="contact__container__content__box__data__text">{text.contact.appointment.text} {appointmentApp.websiteName}.</span>
                            <Button text={appointmentApp.websiteName} className="contact__container__content__box__data__button" link={appointmentApp.websiteUrl} newWindow/>
                        </div>
                    </div>
                    <div className="contact__container__content__box">
                        <span className="contact__container__content__box__title">{text.contact.hours.title}</span>
                        <div className="contact__container__content__box__data">
                            {renderOpeningHours()}
                            <span className="contact__container__content__box__data__text bold underline centred mt2">{text.contact.hours.onlyWithAppointment}</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;