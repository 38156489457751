import React from "react";

import "./Button.scss";

interface ButtonProps {
    text: string;
    className?: string;
    link?: string;
    newWindow?: boolean;
    onClick?: () => void;
    colored? : boolean;
}

const Button = ({text, className, link, newWindow, onClick, colored}: ButtonProps): React.JSX.Element => {
    return (
        link ? <a href={link} target={newWindow ? "_blank" : "_self"} rel={newWindow ? "noreferrer" : ""} className={"button " + (colored ? "colored " : "") + className} onClick={onClick && onClick }>{text}</a> : 
        <button className={"button " + (colored ? "colored " : "") + className} onClick={onClick && onClick }>{text}</button>
    );
}

export default Button;