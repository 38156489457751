import React, { Dispatch, JSX, SetStateAction } from "react"

import Modal from '@mui/material/Modal';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Rating from '@mui/material/Rating';
import Button from '@mui/material/Button';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';

import { TestimonialsData, ApiResult } from "./Testimonials";

import { newData, updateData } from "./functions";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};


interface IProps {
    open: boolean;
    handleClose: (reset?: boolean) => void;
    data: TestimonialsData;
    setModalData: Dispatch<SetStateAction<TestimonialsData>>;
    newItem?: boolean;
    setData: Dispatch<React.SetStateAction<ApiResult | undefined>>
}
const TestimonialsModal = ({ open, handleClose, data, setModalData, newItem, setData }: IProps): JSX.Element => {
    return (<Modal
        open={open}
        onClose={() => {handleClose()}}
        aria-labelledby="Testimonials-modal"
    >
        <Box sx={style}>
            <Typography variant="h6" component="h2">{newItem ? "Ajout d'un témoignage" : "Mise à jour d'un témoignage"}</Typography>
            <TextField required label="Nom" value={data.name} sx={{ width: "100%", marginTop: "1rem" }} onChange={(e) => setModalData({ ...data, name: e.target.value })} />
            <TextField required multiline rows={3} label="Commentaire" value={data.comment} sx={{ width: "100%", marginTop: "1rem" }} onChange={(e) => setModalData({ ...data, comment: e.target.value })} />
            <Rating name="stars" value={data.stars} precision={0.5} onChange={(e, newValue) => setModalData({ ...data, stars: newValue as number })} size="large" />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    value={dayjs(data.date)}
                    onChange={(newValue: Dayjs | null) => {
                        if (newValue)
                            setModalData({ ...data, date: newValue.toDate() })
                    }}
                    format="DD/MM/YYYY"
                />
            </LocalizationProvider>
            <Button type="button" variant="contained" sx={{ marginTop: "1rem" }} onClick={() => {handleClose(true)}} color="error">Annuler</Button>
            <Button type="button" variant="contained" sx={{ marginTop: "1rem", marginLeft: "1rem" }} color="success" onClick={async () => {
                const res: boolean = newItem ? await newData(data) : await updateData(data);
                if (res) {
                    setData(undefined);
                    handleClose(true);
                } else {
                    handleClose();
                }
            }} disabled={data.name !== "" && data.comment !== "" && data.stars !== 0 && data.date !== undefined ? false : true}>{newItem ? "Ajouter" : "Modifier"}</Button>
        </Box>
    </Modal>)
}

export default TestimonialsModal;